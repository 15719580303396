/* eslint-disable */

import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Icon, Icomoon, Logo, Link, MobileMenu, Button } from "../..";
import { AppState } from "../../utils";
import { InspirationDropdown } from "./InspirationDropdown/InspirationDropdown";
import { DestinationDropdown, DropdownCountry } from "./DestinationDropdown/DestinationDropdown";
import { ReimagineTravelDropdown } from "./ReimagineTravelDropdown/ReimagineTravelDropdown";
import { LocaleSelector } from "../../molecules";
import { AccountDropdown } from "./AccountDropdown/AccountDropdown";
import { MobileMenuProps } from "./MobileMenu/MobileMenu";
import { FAQButton, FAQButtonProps } from "./FaqButton";
import { HowItWorksMenuButton } from "./HowItWorksMenuButton/HowItWorksMenuButton";
import "./Header.scss";

export interface HeaderComponentProps extends Pick<MobileMenuProps, "currentPath">, FAQButtonProps {
  /**
   * The currently signed in user
   */
  user: AppState["user"];

  className?: string;

  /**
   * Countries to show in the destinations dropdown
   */
  countries?: DropdownCountry[];

  onLocaleSelect?: (locale: string) => void;

  hasMainNavigation?: boolean;

  shouldShowYourTrip?: boolean;

  dataTrackingIds?: {
    logo?: string;
    destinations?: string;
    destinationsCountries?: string;
    inspiration?: string;
    inspirationLinks?: string;
    reimagine?: string;
    howItWorks?: string;
    localeModal?: string;
    faq?: string;
    userLinks?: string;
  };
  hasUtilityButtons?: boolean;
}

/**
 * Header of the marketing site.
 *
 * Key features:
 * - fixed to the top of the viewport [1]
 * - collapses when viewport is scrolled
 * - inserts a "spacing" div of the same height into the page to avoid overlapping the content below.
 *
 * [1] Stories are rendered within `iframe`s so `position: fixed` doesn't work the same in Storybook. There is some extra CSS to simulate how it works for documentation purposes.
 *
 * @status development
 * - change enquiry form route based on page
 * - mobile styling + mobile menu
 */

export const HeaderComponent: React.FC<HeaderComponentProps> = (props) => {
  // Whether to show the mobile menu
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <div className={classNames("marketing-header", props.className)}>
        <MenuContent
          {...props}
          isMenuOpen={isMenuOpen}
          setMenuOpen={setMenuOpen}
          // if mainNavigation is falsy value then I want for it to default to value true otherwise keep received value
          hasMainNavigation={props.hasMainNavigation ?? true}
          shouldShowYourTrip={props.shouldShowYourTrip ?? true}
          dataTrackingIds={props.dataTrackingIds}
          hasUtilityButtons={props.hasUtilityButtons ?? true}
        />
      </div>

      <MobileMenu
        isOpen={isMenuOpen}
        onClose={React.useCallback(() => setMenuOpen(false), [setMenuOpen])}
        currentUser={props.user}
        currentPath={props.currentPath}
        faqUrl={props.faqUrl}
        onLocaleSelect={props.onLocaleSelect}
        shouldShowYourTrip={props.shouldShowYourTrip ?? true}
        dataTrackingIds={props.dataTrackingIds}
      />
    </>
  );
};

const MenuContent: React.FC<
  HeaderComponentProps & {
    isMenuOpen: boolean;
    setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
    hasMainNavigation?: boolean;
  }
> = memo((props) => {
  const { t } = useTranslation("ui");

  return (
    <div className="marketing-header__wrap">
      <nav
        className="level is-mobile is-marginless marketing-header__nav"
        role="navigation"
        aria-label={t("Header_ariaLabel", "Main menu")}>
        <div className="level-left">
          <div className="level-item">
            <Link
              className="is-block m-0 marketing-header__logo-container"
              href={t("routes:index", "")}
              data-tracking-id={props?.dataTrackingIds?.logo}>
              <Logo className="marketing-header__logo" width={80} />
            </Link>
          </div>
          {props.hasMainNavigation && (
            <>
              <div data-testid="destination-dropdown" className={"level-item is-hidden-touch"}>
                <DestinationDropdown
                  countries={props.countries}
                  dataTrackingIdButton={props.dataTrackingIds?.destinations}
                  dataTrackingIdLink={props.dataTrackingIds?.destinationsCountries}
                />
              </div>
              <div data-testid="inspiration-dropdown" className={"level-item is-hidden-touch"}>
                <InspirationDropdown
                  dataTrackingIdButton={props.dataTrackingIds?.inspiration}
                  dataTrackingIdLinks={props.dataTrackingIds?.inspirationLinks}
                />
              </div>
              <div data-testid="reimagine-dropdown" className="level-item is-hidden-touch">
                <ReimagineTravelDropdown dataTrackingId={props.dataTrackingIds?.reimagine} />
              </div>
              <div data-testid="howItWorks-button" className={"level-item is-hidden-touch"}>
                <HowItWorksMenuButton dataTrackingId={props.dataTrackingIds?.howItWorks} />
              </div>
            </>
          )}
        </div>

        <div className="level-right mr-3">
          {props.shouldShowYourTrip && (
            <Link
              className="button m-2 mr-3 is-ghost"
              href={t("routes:customerIndex", "/my-account/")}>
              {t("Header_tripLabel", "Your account")}
            </Link>
          )}

          {props.hasUtilityButtons && (
            <>
              <LocaleSelector
                className="is-hidden-mobile"
                direction="down-left"
                onClick={props.onLocaleSelect}
                dataTrackingId={props.dataTrackingIds?.localeModal}
              />

              {props.faqUrl && (
                <FAQButton
                  className="is-hidden-mobile"
                  faqUrl={props.faqUrl}
                  dataTrackingId={props.dataTrackingIds?.faq}
                />
              )}

              <AccountDropdown
                className="is-hidden-mobile"
                user={props.user}
                dataTrackingId={props.dataTrackingIds?.userLinks}
              />

              <Button
                element="button"
                className={classNames("is-link p-3 is-flex is-hidden-desktop", {
                  "is-hidden": !props.hasMainNavigation,
                })}
                onClick={() => props.setMenuOpen(true)}
                aria-haspopup="true"
                aria-expanded={props.isMenuOpen}>
                <Icon icomoon={Icomoon.menu7} style={{ fontSize: 23 }} />
              </Button>
            </>
          )}
        </div>
      </nav>
    </div>
  );
});
