import React from "react";
import { Separator } from "./Separator";

import "./Stepper.scss";

interface Step {
  title: string;
  label?: string;
}

interface Props {
  steps: Array<Step>;
}

export const Stepper: React.FC<Props> = ({ steps }) => {
  return (
    <div className="stepper">
      {steps.map((step, i) => {
        const isFirstStep = i === 0;
        const isLastStep = i === steps.length - 1;
        const position = i === 1 ? "second-item" : i === 2 ? "third-item" : "";
        return (
          <React.Fragment key={i}>
            <Step
              step={step}
              isFirstStep={isFirstStep}
              isLastStep={isLastStep}
              positionClass={position}
            />
            {i < steps.length - 1 && <Separator fixedHeight />}
          </React.Fragment>
        );
      })}
    </div>
  );
};

interface StepProps {
  step: Step;
  isFirstStep: boolean;
  isLastStep: boolean;
  positionClass: string;
}

export const Step: React.FC<StepProps> = ({ step, isFirstStep, isLastStep, positionClass }) => {
  return (
    <div className={`step ${positionClass}`}>
      <div className="circle-wrapper">
        <Separator invisible={isFirstStep} />
        <Circle isActive={isFirstStep} />
        <Separator invisible={isLastStep} />
      </div>
      <div>
        {step.label && <p className="label primary">{step.label}</p>}
        <p className="title primary">{step.title}</p>
      </div>
    </div>
  );
};

interface CircleProps {
  isActive: boolean;
}

const Circle = ({ isActive }: CircleProps) => {
  return (
    <div className={`stepper-circle ${isActive ? "stepper-circle-active" : ""}`}>
      {isActive && <div className="stepper-inner-circle" />}
    </div>
  );
};
